import React, { useCallback, useMemo, useState } from 'react';

import { flatten } from 'lodash';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useGetFinancialInstitutionConnectionInfiniteScroll } from '@src/hooks/queries/financial_institution_connection';
import { TID } from '@src/types/common';

import SideView from '@src/components/ui/side_view';

import { IBankAccountData } from '../schema';
import Table from './financial_institution_connection/table';

import styles from '../styles.module.scss';

interface IBankAccountProps extends Omit<IUseModalProps, 'onDone'> {
  bankAccount?: IBankAccountData,
  businessId?: TID,
  onDone: (selectedBankAccount: IBankAccountData) => void,
}

const BankAccount = ({
  isOpen,
  bankAccount,
  businessId,
  onCancel,
  onDone,
}: IBankAccountProps) => {
  const [selectedAccount, setSelectedAccount] = useState<IBankAccountData | undefined>(bankAccount);

  const query = useGetFinancialInstitutionConnectionInfiniteScroll({
    businessId,
  });

  const financialInstitutionConnectionsPages = useMemo(() => {
    return query.data?.pages || [];
  }, [query.data]);

  const handleClick = useCallback((account: IBankAccountData) => {
    setSelectedAccount(account);
    onDone(account);
  }, [onDone]);

  const handleDone = useCallback(() => {
    if (!selectedAccount) return;

    onDone(selectedAccount);
  }, [onDone, selectedAccount]);

  const financialInstitutionConnections = useMemo(() => {
    return flatten(financialInstitutionConnectionsPages.map((item) => {
      return item.collection;
    }));
  }, [financialInstitutionConnectionsPages]);

  return (
    <SideView.Standard
      hideActions
      isOpen={ isOpen }
      title="Select Account"
      onCancel={ onCancel }
      onDone={ handleDone }
    >
      { () => (
        <div className={ styles['financial-institution-connection-center-list'] }>
          <span>
            Select an account from below available financial institution accounts
            { ' ' }
            or add a new one in
            { ' ' }
            <a
              className="font-bold pointer"
              href={ `/financial_institution_connection_center/${businessId}` }
              role="button"
              tabIndex={ 0 }
            >
              Financial Connections
            </a>
          </span>
          <Table
            financialInstitutionConnections={ financialInstitutionConnections }
            query={ query }
            onSelected={ handleClick }
          />
        </div>
      ) }
    </SideView.Standard>
  );
};

const MemoizedBankAccount = React.memo(BankAccount);

const useSelectBankAccount = makeUseModal(MemoizedBankAccount);

export {
  useSelectBankAccount,
  MemoizedBankAccount as default,
};
