import { useMutation, useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { createUseGetInfiniteCollection } from '@src/hooks/queries/infinite_collection_queries';
import { IAdjustmentEntry } from '@src/types/adjustment_entries';
import { TID } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete, apiGet, apiPost, apiPut, authorizedApiRequest } from './helpers';

interface IGetAdjustmentEntriesResponse {
  meta: {
    totalCount: number,
  },
  collection: IAdjustmentEntry[],
}

interface IGetAdjustmentEntriesParams {}

// eslint-disable-next-line max-len
const getAdjustmentEntries = (params: IGetAdjustmentEntriesParams): Promise<IGetAdjustmentEntriesResponse> => {
  const url = '/api/v1/adjustment_entries';

  return apiGet(url, { ...underscoreKeys(params) }).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta:       cdata.meta,
      collection: cdata.adjustmentEntries,
    } as IGetAdjustmentEntriesResponse;
  });
};

const useGetAdjustmentEntries = createUseGetInfiniteCollection<
  IAdjustmentEntry,
  IGetAdjustmentEntriesParams,
  IGetAdjustmentEntriesResponse
>({
  queryKey: QueryKey.adjustmentEntries,
  request:  getAdjustmentEntries,
});

interface IRcJournalEntryParams {
  id?: TID;
  businessId: number;
  entryDate: string;
  splits: {
    chartOfAccountId: string;
    entityType: string;
    entityId: string;
    debits: number;
    credits: number;
    memo: string;
    accountingClassId: string;
  }[]
}

const createRcJournalEntry = (params: IRcJournalEntryParams): Promise<IAdjustmentEntry> => {
  return apiPost('/api/v1/adjustment_entries', underscoreKeys(params)).then((data) => <any>camelizeKeys(data.adjustment_entry));
};

const useCreateRcJournalEntry = () => {
  return useMutation<IAdjustmentEntry, Error, IRcJournalEntryParams>(createRcJournalEntry);
};

const updateRcJournalEntry = (params: IRcJournalEntryParams): Promise<void> => {
  return apiPut(`/api/v1/adjustment_entries/${params.id}`, underscoreKeys(params));
};

const useUpdateRcJournalEntry = () => {
  return useMutation<void, Error, IRcJournalEntryParams>(updateRcJournalEntry);
};
interface IGetAdjustmentEntryResponse {
  adjustmentEntry: IAdjustmentEntry
}

const getAdjustmentEntry = (id: TID, businessId: TID): Promise<IGetAdjustmentEntryResponse> => {
  return apiGet(
    `/api/v1/adjustment_entries/${id}`,
    { business_id: businessId },
  ).then((data) => <any>camelizeKeys(data));
};

const useGetAdjustmentEntry = (id: number, businessId: number) => {
  return useQuery<IGetAdjustmentEntryResponse, Error>({
    queryKey: `${QueryKey.adjustmentEntry}-${id}`,
    queryFn:  () => getAdjustmentEntry(id, businessId),
  });
};

interface IGetAdjustmentEntryParams {
  qboId?: string;
  docytId?: string;
  date?: string
}

const getAdjustmentEntryByParams = (
  params: IGetAdjustmentEntryParams,
  businessId: TID,
): Promise<IGetAdjustmentEntryResponse> => {
  return apiGet(
    '/api/v1/adjustment_entries/detail_by_params',
    underscoreKeys({ ...params, businessId }),
  ).then((data) => <any>camelizeKeys(data));
};

const useGetAdjustmentEntryByParams = (
  params: IGetAdjustmentEntryParams,
  businessId: number,
) => {
  return useQuery<IGetAdjustmentEntryResponse, Error>({
    queryKey: [QueryKey.adjustmentEntry, params.qboId ?? params.docytId],
    queryFn:  () => getAdjustmentEntryByParams(params, businessId),
  });
};

const deleteAdjustmentEntry = (params: IDeleteAdjustmentEntryParams): Promise<void> => {
  return apiDelete(
    `/api/v1/adjustment_entries/${params.id}`,
    { business_id: params.businessId },
  );
};

const getAdjustmentEntriesCsv = async (params: IGetAdjustmentEntriesParams): Promise<Blob> => {
  const url = 'api/v1/adjustment_entries.csv';

  return authorizedApiRequest({
    url,
    method:       'GET',
    responseType: 'blob',
    params,
  }).then((blob) => blob);
};

interface IDeleteAdjustmentEntryParams {
  id: TID;
  businessId: TID;
}

const useDeleteAdjustmentEntry = () => {
  return useMutation<void, Error, IDeleteAdjustmentEntryParams>(deleteAdjustmentEntry);
};

const useGetAdjustmentEntriesCsv = () => {
  return useMutation<Blob, Error, IGetAdjustmentEntriesParams>(getAdjustmentEntriesCsv);
};

interface ISearchVendorCustomersResponse {
  meta: {
    totalCount: number,
  },
  collection: IVendorCustomer[],
}

interface IVendorCustomer {
  id: number;
  name: string;
  type: 'QuickbooksCustomer' | 'Vendor';
  displayType: string;
}

interface IVendorCustomersParams {
  businessId: number,
  search?: string,
  searchQuery?: string,
  page?: number,
  perPage?: number,
}

const searchVendorCustomers = (
  params: IVendorCustomersParams,
): Promise<ISearchVendorCustomersResponse> => {
  return apiGet(
    '/api/v1/adjustment_entries/vendor_customers_search',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.vendorCustomers,
    } as ISearchVendorCustomersResponse;
  });
};

export {
  IGetAdjustmentEntriesResponse,
  useGetAdjustmentEntries,
  useCreateRcJournalEntry,
  useGetAdjustmentEntry,
  IDeleteAdjustmentEntryParams,
  useDeleteAdjustmentEntry,
  useUpdateRcJournalEntry,
  getAdjustmentEntriesCsv,
  useGetAdjustmentEntriesCsv,
  searchVendorCustomers,
  useGetAdjustmentEntryByParams,
  IGetAdjustmentEntryParams,
};
