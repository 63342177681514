import React, { FC } from 'react';

import DirectFetchContent from './direct_fetch_content';
import EventDrivenFetchContent from './event_driven_fetch_content';

const getQueryParams = () => {
  const params = new URLSearchParams(window.location.search);
  const qboId = params.get('qbo_id') || undefined;
  const docytId = params.get('docyt_id') || undefined;
  const date = params.get('date') || undefined;
  return { qboId, docytId, date };
};

const Content: FC = () => {
  const params = getQueryParams();

  if (params.qboId && params.date) {
    return <EventDrivenFetchContent params={ params } />;
  }

  return <DirectFetchContent params={ params } />;
};

export default Content;
