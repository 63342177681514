/* eslint-disable import/order */
import React from 'react';

import { IBusiness } from '@src/types/businesses';
import { IClientBusiness } from '@src/types/clients';

import { useCreatePortfolioModal } from '../business_reports/custom_reports/modals/create_portfolio';
import { PlusIconCircle } from '../utils/fa_icons';

import styles from './styles.module.scss';

const AddNewPortfolio = ({ businesses, onCreate }: {
  businesses: IBusiness[] | IClientBusiness[];
  onCreate: () => void;
}) => {
  const CreatePortfolioModal = useCreatePortfolioModal();

  const flag = window.Docyt.Common.Constants.ENABLE_CREATE_PORTFOLIO_OPTION;
  if(!window.configData.launch_darkly_enabled_global_features.includes(flag)){
    return <></>
  }

  return (
    <>
      <li className={ styles['border-top'] }>
        <a
          className="submenu-title"
          role="button"
          tabIndex={ 0 }
          onClick={ () => CreatePortfolioModal.open() }
        >
          <div className="m-l-18 m-t-8 m-b-8">
            <PlusIconCircle />
            <span className="m-l-5">Add New Portfolio</span>
          </div>
        </a>
      </li>
      <CreatePortfolioModal.Component
        { ...CreatePortfolioModal.props }
        businesses={ businesses }
        onCreate={ onCreate }
      />
    </>
  )
}

export default AddNewPortfolio;