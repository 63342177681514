import React, { useEffect, useState } from 'react';

import { ICreateChartOfAccountParams, useCreateChartOfAccount } from '@src/requests/simple_chart_of_accounts';

import useProgressModal from './progress_modal';
import useResultModal from './result_modal';

const MultipleCreateCoas: React.FC<{ rows: ICreateChartOfAccountParams[], totalCount: number }> =
    ({ rows, totalCount }) => {
      const { mutateAsync } = useCreateChartOfAccount();
      const progressModal = useProgressModal();
      const resultModal = useResultModal();
      const [doneCount, setDoneCount] = useState(0);
      const [errorMessages, setErrorMessages] = useState<string[]>([]);

      const createCoaNode = async (row: ICreateChartOfAccountParams) => {
        if (row.line && row.line > 0) {
          try {
            const { ...rowWithoutChildren } = row;
            await mutateAsync(rowWithoutChildren);
          } catch (error: any) {
            setErrorMessages((prevErrors) => [...prevErrors, `Row ${row.line} - ${row.name} : ${error.message}`]);
          } finally {
            setDoneCount((count) => count + 1);
          }
        }
        if (row.children?.length) {
          // Process children using Promise.all instead of sequential for loop
          await Promise.all(row.children.map((child) => createCoaNode(child)));
        }
      };

      const createCoas = async () => {
        progressModal.open();
        const topLevelPromises = rows.map((row) => createCoaNode(row));
        await Promise.all(topLevelPromises);
        progressModal.props.onDone();
        resultModal.open();
      };

      useEffect(() => {
        createCoas();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      return (
        <>
          <progressModal.Component
            doneCount={ doneCount }
            totalCount={ totalCount }
            { ...progressModal.props }
          />
          <resultModal.Component
            errors={ errorMessages }
            totalCount={ totalCount }
            { ...resultModal.props }
          />
        </>
      );
    };

export default MultipleCreateCoas;
