import React, { useEffect, useState } from 'react';

import { getPortfolioDashboardById } from '@src/requests/portfolio_service/portfolio';
import { portfolioDashboardsPath } from '@src/routes';
import { TID, TUUID } from '@src/types/common';
import { IPortfolioDashboard } from '@src/types/portfolio';

import { ErrorNotification } from '@src/components/ui/notification';

import { DashboardDetails } from './details';
import { DashboardSideMenu } from './side_menu';

import styles from './styles.module.scss';
import { useGetPortfolioDashboard } from '@src/hooks/queries/portfolio_dashboard';

interface IPortfolioDashboardMainPageProps {
  portfolioId: TID,
  dashboardId: TUUID,
  widgetId?: string,
}

const PortfolioDashboardMainPage = ({
  portfolioId,
  dashboardId,
  widgetId,
}: IPortfolioDashboardMainPageProps): JSX.Element => {
  const [portfolioDashboards, setPortfolioDashboards] =
    useState<IPortfolioDashboard | undefined>(undefined);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const response = useGetPortfolioDashboard({id: dashboardId});

  useEffect(() => {
    if(response.status === "success"){
      setPortfolioDashboards(response.data.dashboard);
    } else {
      localStorage.removeItem('portfolio-id');
      localStorage.removeItem('portfolio-dashboard-id');
      Backbone.history.navigate(portfolioDashboardsPath(), { trigger: true });
      setErrorMsg(response.error?.message);
    }
  },[response]);

  return (
    <div className={ styles['main-container'] }>
      {portfolioDashboards && portfolioDashboards.businesses &&
        (
          <>
            { errorMsg && <ErrorNotification message={ errorMsg } title="Warning!" onHidden={ () => setErrorMsg(undefined) } /> }
            <DashboardSideMenu
              dashboard={ portfolioDashboards }
              widgetId={ widgetId || portfolioDashboards?.widgetTemplates[0]?.id }
            />
            <DashboardDetails
              businesses={ portfolioDashboards.businesses }
              dashboard={ portfolioDashboards }
              widgetId={ widgetId }
            />
          </>
        )
      }
    </div>
  );
};

export default PortfolioDashboardMainPage;
