import React from 'react';

import classNames from 'classnames';

import { currencyLocaleFormatter } from '@src/utils/currency';

import { IInvoiceQueueCollection } from '../hooks';

import styles from '../styles.module.scss';

interface IBannerInfoProps {
  collection: IInvoiceQueueCollection,
}

const BannerInfo = ({ collection }: IBannerInfoProps) => {
  const classesBorder = classNames(styles['info-item'], styles.border);

  return (
    <div className={ styles['banner-info'] }>
      <div className={ classesBorder }>
        <span className={ styles.label }>
          Uncleared Payments
        </span>
        <span className={ styles['txt-value'] }>
          { currencyLocaleFormatter(collection.summaryInfo?.amountUnclearedPayments) }
        </span>
      </div>
      <div className={ classesBorder }>
        <span className={ styles.label }>
          Bank Ledger Balance
        </span>
        <span className={ styles['txt-value'] }>
          { currencyLocaleFormatter(collection.summaryInfo?.ledgerBalance) }
        </span>
      </div>
      <div className={ styles['info-item'] }>
        <span className={ styles.label }>
          Ready for Credit:
          (
          { collection.summaryInfo?.countReadyForCredit }
          )
        </span>
        <span className={ styles['txt-value'] }>
          { currencyLocaleFormatter(collection.summaryInfo?.amountReadyForCredit) }
        </span>
      </div>
      <span className="font-24 font-bold">+</span>
      <div className={ styles['info-item'] }>
        <span className={ styles.label }>
          Needs Approval / Ready to Pay:
          (
          { collection.summaryInfo?.countValidPaying }
          )
        </span>
        <span className={ styles['txt-value'] }>
          { currencyLocaleFormatter(collection.summaryInfo?.amountValidPaying) }
        </span>
      </div>
      <span className="font-24 font-bold">+</span>
      <div className={ styles['info-item'] }>
        <span className={ styles.label }>
          Settlement Items:
          (
          { collection.summaryInfo?.countSettlementItems }
          )
        </span>
        <span className={ styles['txt-value'] }>
          { currencyLocaleFormatter(collection.summaryInfo?.amountSettlementItems) }
        </span>
      </div>
      <span className="font-24 font-bold">=</span>
      <div className={ classesBorder }>
        <span className={ styles.label }>
          Invoices
          (
          { collection.summaryInfo?.countServiceDocuments }
          )
        </span>
        <span className={ styles['txt-value'] }>
          { currencyLocaleFormatter(collection.summaryInfo?.amountServiceDocuments) }
        </span>
      </div>
      <div className={ styles['info-item'] }>
        <span className={ styles.label }>
          Expense reports
          (
          { collection.summaryInfo?.countExpenseReport }
          )
        </span>
        <span className={ styles['txt-value'] }>
          { currencyLocaleFormatter(collection.summaryInfo?.amountExpenseReport) }
        </span>
      </div>
    </div>
  );
};

export default React.memo(BannerInfo);
