import React from 'react';

import styles from './styles.module.scss';

const InfoBanner = () => {
  return (
    <div className={ styles['info-banner'] }>
      <span>
        <i className="icon icon-info-fill" />
        The settlement items feature has been deprecated. No new settlement items will be created.
      </span>
      <a href="https://pages.docyt.com/knowledge" rel="noopener noreferrer" target="_blank">
        Learn More
      </a>
    </div>
  );
};

export default React.memo(InfoBanner);
