import React, { useCallback, useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useCreateRevenueReportType } from '@src/hooks/queries/revenue_report_types';
import { ICreateRevenueReportTypeResponse } from '@src/requests/revenue_report_types';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form from './form';
import { IAddReportTypeValues } from './schema';

interface IAddReportTypeModalProps extends IUseModalProps {}

const AddReportTypeModal = ({
  isOpen,
  onDone,
  onCancel: onCloseModal,
}: IAddReportTypeModalProps) => {
  const business = useBusinessContext();
  const revenueService = useRevenueServiceContext();

  const mutation = useCreateRevenueReportType();
  const { mutate : createRevenueReportType } = mutation;

  const [step, setStep] = useState<number>(1);

  const handleSubmit = useCallback((values: IAddReportTypeValues) => {
    if (step < 3) {
      setStep(step + 1);
    } else {
      // TODO: Refactor the API call triggers. The create revenue report type API should be called only after all report type details are collected.
      // Currently, it is triggered upon entering just the name, which is not the intended behavior.
      createRevenueReportType({
        revenueId:                 revenueService.id,
        name:                      values.name,
        frequency:                 values.frequency,
        split_revenue_receivables: values.split_revenue_receivables === 'true',
      }, {
        onSuccess: (data : ICreateRevenueReportTypeResponse) => {
          Backbone.history.navigate(
            `/businesses/${business.id}/new_revenue_service/settings/report_types/${data.revenueReportType.id}`,
            { trigger: true },
          );
          onDone();
        },
      });
    }
  }, [step, business.id, createRevenueReportType, onDone, revenueService.id]);

  const getTitleModal = (steps: number) => {
    switch (steps) {
      case 1:
        return 'Add New Revenue Mapping';
      case 2:
        return 'Select Data Frequency';
      case 3:
        return 'Select Revenue by Payment Processor Tracking';
      default:
        return '';
    }
  };

  const handleCancel = useCallback(() => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      onCloseModal();
    }
  }, [onCloseModal, step]);

  return (
    <>
      <MutationStatus
        mutation={ mutation }
      />
      <Modal.Form
        cancelTitle="Go Back"
        proceedTitle={ step < 3 ? 'Next' : 'Submit' }
        show={ isOpen }
        title={ getTitleModal(step) }
        onCancel={ handleCancel }
      >
        { ({ formId }) => (
          <>
            {step === 1 && <p>Please add your revenue mapping name</p>}
            <Form
              formId={ formId }
              step={ step }
              onSubmit={ handleSubmit }
            />
          </>
        )}
      </Modal.Form>
    </>
  );
};

const useAddReportTypeModal = makeUseModal(AddReportTypeModal);

export {
  useAddReportTypeModal,
  AddReportTypeModal as default,
};
