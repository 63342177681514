import React from 'react';

import { Button } from '@src/components/ui_v2/buttons';

import { useAddReportTypeModal } from './add_report_type_modal';

const AddRevenueSystem = (): JSX.Element => {
  const addReportTypeModal = useAddReportTypeModal();

  return (
    <>
      <addReportTypeModal.Component
        { ...addReportTypeModal.props }
      />
      <Button
        variant="link"
        onClick={ addReportTypeModal.open }
      >
        Add Mapping
      </Button>
    </>
  );
};

export default AddRevenueSystem;
