import { object, string } from 'yup';

interface IAddReportTypeValues {
  name: string
  frequency: string
  split_revenue_receivables: string
}

const addReportTypeValidation = object({
  name:                      string().trim().label('Revenue Mapping Name').required(),
  frequency:                 string(),
  split_revenue_receivables: string(),
});

export {
  addReportTypeValidation,
  IAddReportTypeValues,
};
