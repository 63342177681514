import React, { memo } from 'react';

import { SpinnerIcon } from '@src/components/utils/fa_icons';
import { AiChatLogoIcon } from '@src/components/utils/img_icons';

import ChatMessageItem from './chat_message_item';

import styles from '../styles.module.scss';

interface IChatMessageProps {
  question: string;
  answer?: string;
  loader: boolean;
  lastResponseId: string;
  messageId: string;
  setLastResponseId : (val:string)=>void;
  scrollToBottom: ()=>void;
}

const ChatMessage = ({ question, answer, loader,
  lastResponseId, messageId, setLastResponseId, scrollToBottom } : IChatMessageProps) => {
  return (
    <div className={ styles['chat-message'] }>
      <div className={ styles['chat-message-question'] }>{question}</div>
      <div className={ styles['chat-message-answer-container'] }>
        <div className={ styles['chat-message-answer-logo'] }>
          <AiChatLogoIcon />
          {' '}
        </div>
        {(!answer && loader) ? (
          <span>
            <SpinnerIcon spin />
          </span>
        ) : answer && (
        <ChatMessageItem
          content={ answer }
          lastResponseId={ lastResponseId }
          messageId={ messageId }
          scrollToBottom={ scrollToBottom }
          setLastResponseId={ setLastResponseId }
        />
        )}
      </div>
    </div>
  );
};

export default memo(ChatMessage);
