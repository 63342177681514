import React from 'react';

import Markdown from 'markdown-to-jsx';

import { useTypingAnimation } from '../hooks/use_typing_animation';

import styles from '../styles.module.scss';

interface IChatMessageItemProps {
  content: string ;
  lastResponseId:string;
  messageId:string;
  setLastResponseId : (val:string) => void;
  scrollToBottom: () => void;
}

const ChatMessageItem: React.FC<IChatMessageItemProps> =
({ content, lastResponseId, messageId, setLastResponseId, scrollToBottom }) => {
  const { displayedText } = useTypingAnimation({
    content,
    lastResponseId,
    messageId,
    scrollToBottom,
    setLastResponseId,
  });
  const handleLinkClick = (event : any) => {
    event.preventDefault();
    event.stopPropagation();
    window.open(event.target.href, '_blank');
  };

  return (
    <Markdown
      className={ styles['chat-message-answer'] }
      options={ {
        overrides: {
          a: {
            component: 'a',
            props:     {
              target:  '_blank',
              onClick: handleLinkClick,
            },
          },
        },
      } }
    >
      {displayedText}
    </Markdown>
  );
};

export default ChatMessageItem;
