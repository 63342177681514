import React, { useState } from 'react';

import {
  accountsPayablePath,
  accountsPayablePayeesPath,
  accountsPayableSettingsQueuePath,
  checkRegisterPath,
  docytBusinessNetworkPath,
  expenseReportsServicePath,
  invoiceQueuePath,
  myVendorsPath,
  paymentRulesQueuePath,
  receiptBoxSettingsPath,
  receiptListPath,
  achTransactionsPath,
  settlementItemsPath,
  vendors1099ReportsPath,
  businessSettingsPath,
} from '@src/routes';
import { IBusinessServicesInfo } from '@src/types/business_services_info';
import { TID } from '@src/types/common';
import { isFeatureEnabled, LDFeatureForBusinessEnabled, LDFeatureValueForBusiness } from '@src/utils/config';

import ServiceItem from '../module_header_item';
import ModuleHeaderMenu from '../module_header_menu';
import ServiceSubMenu from '../module_header_sub_menu';

interface IServiceMenuProps {
  businessId: TID,
  info: IBusinessServicesInfo,
  activeItem?: string,
  focusToMyClient: boolean
}

const ExpenseManagementItem = ({
  businessId,
  info,
  activeItem,
  focusToMyClient
}: IServiceMenuProps) => {
  const [achTransactionsEnabled, setAchTransactionsEnabled] =
    useState<string | boolean | undefined>(
      LDFeatureValueForBusiness(
        window.Docyt.Common.Constants.ACH_TRANSACTIONS_FLAG,
        String(businessId),
      ),
    );

  if (achTransactionsEnabled === undefined) {
    LDFeatureForBusinessEnabled(
      window.Docyt.Common.Constants.ACH_TRANSACTIONS_FLAG,
      String(businessId),
    ).then((res) => {
      setAchTransactionsEnabled(res);
    });
  }

  const canVisibleApService = info.isBusinessAdmin || info.canAccessApService;
  const canVisibleRpService = info.isBusinessAdmin || info.canAccessRpService;
  const canVisibleExpenseReportService = info.isBusinessAdmin || info.canAccessExpenseReportService;
  const canVisibleVendorService = info.isBusinessAdmin || info.canAccessVendorService;
  const intercompanyEnabled: boolean = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.INTERCOMPANY_AUTOMATION_FLAG);
  const hasSettlementItems: boolean = info.hasSettlementItems
  const showSettlementItems : boolean = !(intercompanyEnabled && !hasSettlementItems);

  if (!canVisibleApService
    && !canVisibleRpService
    && !canVisibleExpenseReportService
    && !canVisibleVendorService) {
    return null;
  }

  return (
    <ModuleHeaderMenu
      active={ activeItem === window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.EXPENSE_MANAGEMENT }
      focusToMyClient={ focusToMyClient }
      title={ window.Docyt.Common.Constants.MODULE_HEADER_ITEMS.EXPENSE_MANAGEMENT }
    >
      {
        canVisibleApService && (
          <ServiceSubMenu
            serviceUrl={
              info.apServiceReady
                ? invoiceQueuePath(businessId)
                : businessSettingsPath(businessId)
            }
            title={ window.Docyt.Common.Constants.SERVICE_NAMES.AP_SERVICE }
          >
            <ServiceItem
              serviceUrl={
                info.apServiceReady
                  ? accountsPayablePath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Summary"
            />
            <ServiceItem
              serviceUrl={
                info.apServiceReady
                  ? invoiceQueuePath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Invoice Queue"
            />
            <ServiceItem
              serviceUrl={
                info.apServiceReady
                  ? checkRegisterPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Check Register"
            />
            <ServiceItem
              isHidden={ !achTransactionsEnabled }
              serviceUrl={
                info.apServiceReady
                  ? achTransactionsPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="ACH Transactions"
            />
            {showSettlementItems && (
              <ServiceItem
                serviceUrl={
                  info.apServiceReady
                    ? settlementItemsPath(businessId)
                    : businessSettingsPath(businessId)
                }
                title="Settlement Items"
              />
            )}
            <ServiceItem
              serviceUrl={
                info.apServiceReady
                  ? accountsPayablePayeesPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Payees"
            />
            <ServiceItem
              serviceUrl={
                info.apServiceReady
                  ? paymentRulesQueuePath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Payment Rules"
            />
            <ServiceItem
              serviceUrl={
                info.apServiceReady
                  ? accountsPayableSettingsQueuePath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Settings"
            />
          </ServiceSubMenu>
        )
      }
      {
        canVisibleRpService && (
          <ServiceSubMenu
            serviceUrl={
              info.rpServiceReady
                ? receiptListPath(businessId)
                : businessSettingsPath(businessId)
            }
            title={ window.Docyt.Common.Constants.SERVICE_NAMES.RECEIPT_SERVICE }
          >
            <ServiceItem
              serviceUrl={
                info.rpServiceReady
                  ? receiptListPath(businessId)
                  : businessSettingsPath(businessId)
              }
              title="Receipt List"
            />
            { info.canAccessRpServiceSettings && (
              <ServiceItem
                serviceUrl={
                      info.rpServiceReady
                        ? receiptBoxSettingsPath(businessId)
                        : businessSettingsPath(businessId)
                    }
                title="Settings"
              />
            )}
          </ServiceSubMenu>
        )
      }
      {
        canVisibleExpenseReportService && (
          <ServiceItem
            isHidden={ !isFeatureEnabled('expense_ach') }
            serviceUrl={
              info.expenseReportsServiceReady
                ? expenseReportsServicePath(businessId)
                : businessSettingsPath(businessId)
              }
            title={ window.Docyt.Common.Constants.SERVICE_NAMES.EXPENSE_REPORT_SERVICE }
          />
        )
      }
      {
        canVisibleVendorService && (
          <ServiceSubMenu
            isHidden={ !isFeatureEnabled('vendor_service') }
            serviceUrl={ myVendorsPath(businessId) }
            title={ window.Docyt.Common.Constants.SERVICE_NAMES.VENDOR_SERVICE }
          >
            <ServiceItem
              serviceUrl={ myVendorsPath(businessId) }
              title="My Vendors"
            />
            <ServiceItem
              serviceUrl={ docytBusinessNetworkPath(businessId) }
              title="Docyt Business Network"
            />
            <ServiceItem
              serviceUrl={ vendors1099ReportsPath(businessId) }
              title="1099 Reports"
            />
          </ServiceSubMenu>
        )
      }
    </ModuleHeaderMenu>
  );
};

export default ExpenseManagementItem;
