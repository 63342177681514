import React, { useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import * as ld from 'launchdarkly-js-client-sdk';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import useBusinessFeatureFlag from '@src/hooks/use_business_feature_flag';
import { IReport, ITemplate } from '@src/types/report_service/report';

import Form from '@src/components/ui/form';

import { createReportValidation, ICreateNewReportFormValues } from './schema';

interface ICreateNewReportFormProps {
  formId?: string,
  reports: IReport[],
  templates: ITemplate[],
  onSubmit: SubmitHandler<ICreateNewReportFormValues>,
}

const CreateNewReportForm = ({
  formId,
  reports,
  templates,
  onSubmit,
}: ICreateNewReportFormProps): JSX.Element => {
  const business = useBusinessContext();
  const featureFlag = window.Docyt.Common.Constants.VENDOR_PAYMENTS_REPORT_FLAG;
  const hideVendorPayments = useBusinessFeatureFlag(featureFlag, business.id);
  const [newReportList, setNewReportList] = useState<string[]>([]);

  useEffect(() => {
    const client: ld.LDClient = ld.initialize(window.configData.launch_darkly.client_side_id, { key: `business-${business.id}` });
    client.on('ready', () => {
      const flagValue = client.variation(window.Docyt.Common.Constants.NEW_REPORT_LIST_ENABLED_FLAG, []);
      setNewReportList(flagValue);
    });
  }, [business.id]);

  const templateOptions = useMemo(() => {
    const filterTemplates: ITemplate[] = templates.filter(
      (template) => !newReportList.includes(template.id) && template.id !== 'revenue_accounting_report'
    );
    const tmpTemplates = filterTemplates.map((template) => {
      const existReport = reports.find((report) => report.templateId === template.id);
      const dependentReport = !template.dependsOn
        || !!reports.find((report) => template.dependsOn.includes(report.templateId));
      const isDepartmentReport = template.id
        === window.Docyt.Common.Constants.CUSTOM_REPORT_TYPE.DEPARTMENTAL_REPORT;
      const isDepartmentalReport = template.category === 'department';
      const isVendPaymentDisabled = hideVendorPayments && template.id === 'vendor_payments_report';
      const disabled = template.draft || !dependentReport || isVendPaymentDisabled
        || (!!existReport && !(isDepartmentalReport && !isDepartmentReport));
      return { value: template.id, label: template.name, disabled };
    });

    return tmpTemplates.filter((tmp) => !tmp.disabled);
  }, [reports, templates, newReportList, hideVendorPayments]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateNewReportFormValues>({
    defaultValues: {
      templateId: templateOptions.find((option) => !option.disabled)?.value,
    },
    resolver: yupResolver(createReportValidation),
  });

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <Form.TextField
        error={ errors.name?.message }
        label="Report Name"
        placeholder="Enter Name of Report"
        { ...register('name') }
      />

      { templates[0].id
        === window.Docyt.Common.Constants.CUSTOM_REPORT_TYPE.LABOR_REPORT
        && templates[0].version === 2
        && templateOptions.length === 0
        && (
          <div className="display-flex justify-content-between">
            <div className="width-30-percent" />
            <div className="width-70-percent in-red font-14">Owner`s report need to be created, for enabling labor report.</div>
          </div>
        ) }

      <Form.RadioCollectionField
        vertical
        error={ errors.templateId?.message }
        label="Report Type"
        options={ templateOptions }
        { ...register('templateId') }
      />
    </Form>
  );
};

export {
  ICreateNewReportFormProps,
  ICreateNewReportFormValues,
  CreateNewReportForm as default,
};
