import React, { useCallback, useMemo, useState, useEffect } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useGetCreditCardAccounts } from '@src/hooks/queries/reconciliation_payment_accounts';
import { TAccountType, TID } from '@src/types/common';

import UnmappedAccountWarning from '@src/components/chart_of_accounts/warning/unmapped_account_warning';
import SideView from '@src/components/ui/side_view';
import Spinner from '@src/components/ui/spinner';
import Table from '@src/components/ui/table';

import Item from './item';
import { IAccountData } from './schema';

import styles from '../../styles.module.scss';

interface IBankAccountProps extends Omit<IUseModalProps, 'onDone'> {
  bankAccount?: IAccountData,
  businessId?: TID,
  accountType: TAccountType
  onDone: (selectedBankAccount: IAccountData) => void,
}

const BankAccount = ({
  isOpen,
  bankAccount,
  accountType,
  businessId,
  onCancel,
  onDone,
}: IBankAccountProps) => {
  const [selectedAccount, setSelectedAccount] = useState<IAccountData | undefined>(bankAccount);
  const [loading, setLoading] = useState(false); // Add loading state

  const accountQuery = useGetCreditCardAccounts({
    businessId,
    accountType,
    enabled: !!businessId,
  });

  const { refetch : refetchAccountQuery, isRefetching } = accountQuery;

  // credit card accounts or the bank accounts of the selected business
  const accounts = useMemo(() => {
    return accountQuery.data?.reconciliationPaymentAccounts || [];
  }, [accountQuery.data?.reconciliationPaymentAccounts]);

  const handleClick = useCallback((account: IAccountData) => {
    setSelectedAccount(account);
    // if the account is mapped with a chart of account, call the onDone callback else show the warning and don't call the onDone callback
    if (!account.qboId) return;

    onDone(account);
  }, [onDone]);

  const handleDone = useCallback(() => {
    if (!selectedAccount) return;

    onDone(selectedAccount);
  }, [onDone, selectedAccount]);

  const handleTryAgain = useCallback(() => {
    refetchAccountQuery();
  }, [refetchAccountQuery]);

  useEffect(() => {
    if (accountQuery.data) {
      const updatedAccounts = accountQuery.data.reconciliationPaymentAccounts || [];
      if (updatedAccounts.find((account) => account.id === selectedAccount?.id)?.qboId) {
        setSelectedAccount(undefined);
      }
    }
  }, [accountQuery.data, selectedAccount]);

  return (
    <SideView.Standard
      hideActions
      isOpen={ isOpen }
      title={ `Select ${accountType === 'credit_card' ? 'Credit Card' : 'Bank Account'}` }
      onCancel={ onCancel }
      onDone={ handleDone }
    >
      { () => (
        <div className={ styles['financial-institution-connection-center-list'] }>
          <Table
            className={ styles['finacial-institution-connection-center-table'] }
            wrapperClassName="banking-transactions-table-wrapper"
          >
            <Table.Head>
              <Table.Row className={ styles['accounts-item-header'] }>
                <Table.Cell className={ styles['accounts-item-header-cell'] } widthPercent={ 45 }>
                  <span>{accountType === 'credit_card' ? 'Credit Card' : 'Bank Account'}</span>
                </Table.Cell>
                <Table.Cell className={ styles['accounts-item-header-cell'] } widthPercent={ 55 }>
                  <span>Last 4 Digits</span>
                </Table.Cell>
              </Table.Row>
              {/* if the account is selected but not mapped with a chart of account, show the warning */}
              {(selectedAccount && !selectedAccount?.qboId) && (
                <Table.Row>
                  <Table.Cell colSpan={ 2 }>
                    <UnmappedAccountWarning
                      businessId={ businessId as TID }
                      loading={ isRefetching }
                      onTryAgain={ handleTryAgain }
                    />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Head>
            <Table.Body>
              { isRefetching && (
                <div className={ styles['spinner-container'] }>
                  <Spinner />
                </div>
              ) }
              { accounts.map((account) => (
                <Item
                  key={ account.id }
                  account={ account }
                  accountType={ accountType }
                  onSelected={ handleClick }
                />
              )) }
            </Table.Body>
          </Table>
        </div>
      ) }
    </SideView.Standard>
  );
};

const MemoizedBankAccount = React.memo(BankAccount);

const useSelectAccount = makeUseModal(MemoizedBankAccount);

export {
  useSelectAccount,
  MemoizedBankAccount as default,
};
