import React, { useMemo } from 'react';

import { useGetVendorW9Info } from '@src/hooks/queries/vendor_w9_info';
import { TID } from '@src/types/common';
import { IW9InfoResponse } from '@src/types/w9_info';

import Reports1099 from './vendor_1099_reports';
import VendorContracts from './vendor_contracts';
import W9Info from './vendor_w9_info';

import styles from '../styles.module.scss';

interface ITaxInfoProps {
  businessId: TID,
  vendorId: TID,
}

const TaxInfo = ({
  businessId,
  vendorId,
}: ITaxInfoProps) => {
  const sectionEnabled = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.NEW_VENDOR_PROFILE_FLAG);

  const query = useGetVendorW9Info({
    businessId,
    id: vendorId,
  });

  const vendorData = useMemo(() => {
    if (!query.data) return [];

    return query.data;
  }, [query.data]);

  return (
    <div className={ styles['vendor-profile-content'] }>
      <div className={ styles['vendor-profile-first-content-row'] }>
        <W9Info
          businessId={ businessId }
          id={ vendorId }
          vendor={ vendorData as IW9InfoResponse }
        />
        {!sectionEnabled && (<Reports1099 vendor={ vendorData as IW9InfoResponse } />)}
      </div>
      {!sectionEnabled && (<VendorContracts vendor={ vendorData as IW9InfoResponse } />)}
    </div>
  );
};

export default TaxInfo;
