import { useEffect, useState } from 'react';

interface IUseTypingAnimationProps {
  content: string;
  lastResponseId: string;
  messageId: string;
  scrollToBottom: () => void;
  setLastResponseId : (val: string) => void;
}

export function useTypingAnimation({
  content,
  lastResponseId,
  messageId,
  scrollToBottom,
  setLastResponseId,
}: IUseTypingAnimationProps) {
  const [displayedText, setDisplayedText] = useState<string>('');
  const [isTypingComplete, setIsTypingComplete] = useState<boolean>(false);

  useEffect(() => {
    if (Number(lastResponseId) !== Number(messageId)) {
      setDisplayedText(content);
      return () => {};
    }

    if (isTypingComplete) {
      setLastResponseId('');
      return () => {};
    }

    let textIndex = 0;
    const wordInterval = setInterval(() => {
      if (textIndex < content.length) {
        setDisplayedText((prevText) => prevText + content[textIndex]);
        textIndex += 1;
        scrollToBottom();
      } else {
        setIsTypingComplete(true);
        clearInterval(wordInterval);
      }
    }, 25);

    return () => clearInterval(wordInterval);
  }, [content, lastResponseId, isTypingComplete, scrollToBottom, setLastResponseId, messageId]);

  return { displayedText, isTypingComplete };
}
