import React from 'react';

import { useRecoilValue } from 'recoil';

import { IReport } from '@src/types/report_service/report';
import { IReportData } from '@src/types/report_service/report_data';

import { ExportReportBanner } from '@src/components/common_v2/banner';

import { reportFilePreparing } from '../../atoms';
import { ReportUpdateBanner, UnincludedTransactionsBanner } from '../banner';

interface IBannersProps {
  report: IReport;
  reportDatas: IReportData[];
}

const Banners = ({ report, reportDatas }: IBannersProps): JSX.Element => {
  const preparingReportFile = useRecoilValue(reportFilePreparing);

  return (
    <>
      <ReportUpdateBanner reportDatas={ reportDatas } />
      <UnincludedTransactionsBanner report={ report } reportDatas={ reportDatas } />
      {preparingReportFile && <ExportReportBanner />}
    </>
  );
};

export default Banners;
