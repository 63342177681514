import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';

interface IResultModalProps extends IUseModalProps {
  success: boolean;
  errorMessage?: string;
}

const ResultModal = ({
  success,
  errorMessage,
  isOpen,
}: IResultModalProps): JSX.Element => {
  return (
    <Modal
      show={ isOpen }
      title="Categorize Transaction"
    >
      <Modal.Body>
        <div>
          {success ? (
            <p>1 Transaction was categorized successfully.</p>
          ) : (
            <>
              <p>Failed to categorize transaction:</p>
              <p style={ { color: 'red' } }>{ errorMessage }</p>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancel-link modal-link-btn"
          fontSize={ 16 }
          title="OK"
          onClick={ () => { window.location.reload(); } }
        />
      </Modal.Footer>
    </Modal>
  );
};

const useResultModal = makeUseModal(ResultModal);
export default useResultModal;
