import { TAccountType, TID } from '@src/types/common';
import { IReconciliationPaymentAccountData, IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPut } from './helpers';

interface IGetReconciliationPaymentAccountsParams {
  businessId: TID
  noConsiderArchive?: boolean,
  isArchived?: boolean,
}

interface IGetReconciliationPaymentAccountByIdParams {
  businessId: TID,
  accountId: TID
}

interface IGetReconciliationPaymentAccountsResponse {
  reconciliationPaymentAccounts: IReconciliationPaymentAccount[]
}

interface IGetCreditCardsResponse {
  reconciliationPaymentAccounts: IReconciliationPaymentAccount[]
}

interface IGetReconciliationPaymentAccountByIdResponse {
  reconciliationPaymentAccount: IReconciliationPaymentAccount
}

interface IPutReconciliationPaymentAccountParams {
  accountId: TID,
  businessId: TID,
  source?: string,
  data: IReconciliationPaymentAccountData,
}

interface IPutReconciliationPaymentAccountResponse {
  reconciliationPaymentAccount: IReconciliationPaymentAccount,
}

const getReconciliationPaymentAccounts = (
  params: IGetReconciliationPaymentAccountsParams,
): Promise<IGetReconciliationPaymentAccountsResponse> => {
  return apiGet(
    '/api/v1/reconciliation_payment_accounts',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IGetReconciliationPaymentAccountsResponse);
};

interface IGetCreditCardsParams {
  businessId?: TID,
  accountType: TAccountType,
  enabled?: boolean,
}

const getCreditCardAccounts = (
  params: IGetCreditCardsParams,
): Promise<IGetCreditCardsResponse> => {
  return apiGet(
    '/api/v1/reconciliation_payment_accounts/list_accounts',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IGetCreditCardsResponse);
};

const getReconciliationPaymentAccountById = (
  params: IGetReconciliationPaymentAccountByIdParams,
): Promise<IGetReconciliationPaymentAccountByIdResponse> => {
  return apiGet(
    `/api/v1/reconciliation_payment_accounts/${params.accountId}`,
    underscoreKeys({ business_id: params.businessId }),
  ).then((data) => camelizeKeys(data) as IGetReconciliationPaymentAccountByIdResponse);
};

interface IGetCreditCardsParams {
  businessId?: TID,
  accountType: TAccountType
}

const putReconciliationPaymentAccount = (
  params: IPutReconciliationPaymentAccountParams,
): Promise<IPutReconciliationPaymentAccountResponse> => {
  return apiPut(
    `/api/v1/reconciliation_payment_accounts/${params.accountId}`,
    underscoreKeys({
      businessId:                   params.businessId,
      ...params.source && { source: params.source },
      reconciliationPaymentAccount: params.data,
    }),
  ).then((data) => camelizeKeys(data) as IPutReconciliationPaymentAccountResponse);
};

export {
  IGetReconciliationPaymentAccountsParams,
  IGetReconciliationPaymentAccountByIdParams,
  IPutReconciliationPaymentAccountParams,
  IGetReconciliationPaymentAccountsResponse,
  IGetReconciliationPaymentAccountByIdResponse,
  IPutReconciliationPaymentAccountResponse,
  IGetCreditCardsResponse,
  IGetCreditCardsParams,
  getReconciliationPaymentAccounts,
  getCreditCardAccounts,
  getReconciliationPaymentAccountById,
  putReconciliationPaymentAccount,
};
