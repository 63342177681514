/* eslint-disable import/order */
import React, { useMemo } from 'react';

import { useGetPortfolio } from '@src/hooks/queries/portfolio_services/portfolio_services';
import {
  portfolioDashboardsWidgetPath,
  multiEntityReportsPath,
} from '@src/routes';
import { IBusiness } from '@src/types/businesses';
import { IClientBusiness } from '@src/types/clients';
import { TLaunchDarklyFeature } from '@src/types/launch_darkly';

import ServiceItem from '../header/module_header/module_header_item';
import ModuleHeaderMenu from '../header/module_header/module_header_menu';
import AddNewPortfolio from './add_new_portfolio';

import styles from '../header/navigation_styles.module.scss';

interface IServiceMenuProps {
  launchDarklyFeature?: TLaunchDarklyFeature,
  businesses: IBusiness[] | IClientBusiness[]
}

const NewPortfolioDropDownList = ({
  launchDarklyFeature,
  businesses,
}: IServiceMenuProps) => {
  // const pathToDropdownItemMap: { [key: string]: string } = {
  //   [portfolioDashboardsPath()]: window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.KPI_DASHBOARD,
  //   [multiEntityReportsPath()]: window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.REPORTS,
  // };

  const currentPath = window.location.pathname;
  const title = window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_TITLE;

  const portfolioResp = useGetPortfolio();

  const portfolioList = useMemo(() => {
    return portfolioResp?.data?.portfolios || [];
  }, [portfolioResp.data]);

  const onCreatePortfolio = () => {
    portfolioResp.refetch();
  };

  const getSelectedPortfolio = () => {
    if(portfolioList.length > 0){
      const selectedPortfolioId = localStorage.getItem('portfolio-id');
      const selectedPortfolio = portfolioList.filter((item) => item.id === Number(selectedPortfolioId));
      if(selectedPortfolio.length){
        return selectedPortfolio[0].name.toUpperCase();
      }
    }
    return window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_TITLE.toUpperCase()
  };

  return (
    <div data-global-feature-toggle={ launchDarklyFeature }>
      <ModuleHeaderMenu
        isGlobalNavigationEnabled
        className="font-16 font-bold"
        focusToMyClient={ null }
        showSelect={ false }
        title={ getSelectedPortfolio() }
      >
        <li className="p-t-8 p-b-4">
          <span className={ `m-l-18 m-b-16 ${styles['dropdown-item-title']}` }>
            { window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_TITLE.toUpperCase() }
          </span>
        </li>
        {portfolioList.map((portfolio) => {
          return (
            <ServiceItem
              key={ portfolio.name }
              serviceUrl={ portfolioDashboardsWidgetPath(portfolio.id) }
              title={ portfolio.name }
            />
          );
        })}
        <AddNewPortfolio
          businesses={ businesses }
          onCreate={ onCreatePortfolio }
        />
      </ModuleHeaderMenu>
    </div>
  );
};

export default NewPortfolioDropDownList;
