import React, { useMemo } from 'react';

import flatten from 'lodash/flatten';

import { useGetFinancialInstitutionConnectionInfiniteScroll } from '@src/hooks/queries/financial_institution_connection';
import { useURLParams } from '@src/hooks/url_params';
import { TID } from '@src/types/common';
import { TFinancialInstitutionConnectionSortColumn } from '@src/types/financial_institution_connection';
import { ISortingParams } from '@src/types/sorting';

import SideView from '@src/components/ui/side_view';

import useConnectFinancialInstitutionModal from './connect_financial_institution/modal';
import { IFinancialInstitutionConnectionFilter } from './filter';
import BlankList from './list_item/blank_list';
import List from './list_item/list';

const DefaultSorting: ISortingParams<TFinancialInstitutionConnectionSortColumn> = {
  orderColumn:    'name',
  orderDirection: 'asc',
};

interface IFinancialInstitutionConnectionDetailsProps {
  businessId?: TID,
}

const FinancialInstitutionConnectionDetails = ({
  businessId,
}: IFinancialInstitutionConnectionDetailsProps): JSX.Element => {
  const { filter } = useURLParams<
  IFinancialInstitutionConnectionFilter,
  TFinancialInstitutionConnectionSortColumn
    >({
      section:        window.Docyt.Common.Constants.FINANCIAL_INSTITUTION_CONNECTION_TYPE,
      defaultSorting: DefaultSorting,
    });

  const query = useGetFinancialInstitutionConnectionInfiniteScroll({ businessId, filter: filter.data });
  const financialInstitutionConnectionsPages = useMemo(() => {
    return query.data?.pages || [];
  }, [query.data?.pages]);

  const financialInstitutionConnections = useMemo(() => {
    return flatten(financialInstitutionConnectionsPages.map((item)=>{
      return item.collection
    }))
  }, [query.data]);

  const noFilteredCount = query.data?.pages[0].meta.noFilteredCount || 0;

  const connectFinancialInstitutionModal = useConnectFinancialInstitutionModal();

  return (
    <div>
      <SideView.Provider>
        <connectFinancialInstitutionModal.Component
          { ...connectFinancialInstitutionModal.props }
          financialInstitutionConnections={ financialInstitutionConnections }
        />
        {
          noFilteredCount === 0 && !query.isLoading
            ? (
              <BlankList
                onConnectFinancialInstitution={ connectFinancialInstitutionModal.open }
              />
            ) : (
              <List
                query={ query }
                businessId={ businessId }
                filter={ filter }
                financialInstitutionConnections={ financialInstitutionConnections }
                refetchFinancialConenctionList={ query.refetch }
                onConnectFinancialInstitution={ connectFinancialInstitutionModal.open }
              />
            )
        }
        <SideView.Render />
      </SideView.Provider>
    </div>
  );
};

export default FinancialInstitutionConnectionDetails;
