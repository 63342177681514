import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import Form from '@src/components/ui_v2/form';

import { addReportTypeValidation, IAddReportTypeValues } from './schema';

import styles from '../styles.module.scss';

interface IAddReportTypeFormProps {
  formId: string;
  step: number;
  onSubmit: (data: IAddReportTypeValues) => void;
}

const AddReportTypeForm = ({ formId, step, onSubmit }: IAddReportTypeFormProps) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<IAddReportTypeValues>({
    defaultValues: {
      split_revenue_receivables: 'true',
      frequency:                 'month_to_date',
    },
    mode:           'onSubmit',
    reValidateMode: 'onChange',
    resolver:       yupResolver(addReportTypeValidation),
  });

  const showFrequencyOptions = [
    { value: 'month_to_date', label: 'Month-To-Date (MTD) Basis' },
    { value: 'daily', label: 'Per-Day Basis Most people select this' },
  ];

  const showSplitRevenueReceivablesOptions = [
    { value: 'true', label: 'Track cash and each merchant processor deposits' },
    { value: 'false', label: 'Do not track cash and merchant processor deposits' },
  ];

  return (
    <Form
      id={ formId }
      onSubmit={ handleSubmit(onSubmit) }
    >
      {step === 1 && (
        <Form.TextField
          error={ errors.name?.message }
          label="Name"
          placeholder="Type Here"
          { ...register('name') }
        />
      )}
      {step === 2 && (
        <Form.RadioField
          hiddenLabel
          label=""
          options={ showFrequencyOptions }
          { ...register('frequency') }
        />
      )}
      {step === 3 && (
        <Form.RadioField
          hiddenLabel
          label=""
          options={ showSplitRevenueReceivablesOptions }
          { ...register('split_revenue_receivables') }
        />
      )}

    </Form>
  );
};

export default AddReportTypeForm;
