import React from 'react';

import { ICreateChartOfAccountParams } from '@src/requests/simple_chart_of_accounts';

import Root from '@src/components/root';

import MultipleCreateCoas from './multiple_create_coas';

const EntryPoint: React.FC<{ rows: ICreateChartOfAccountParams[], totalCount: number }> =
    ({ rows, totalCount }) => {
      return (
        <Root>
          <MultipleCreateCoas rows={ rows } totalCount={ totalCount } />
        </Root>
      );
    };
export default EntryPoint;
