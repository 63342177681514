import React, { useMemo } from 'react';

import flatten from 'lodash/flatten';
import omit from 'lodash/omit';

import { useGetAccountsPayableServiceDocuments } from '@src/hooks/queries/accounts_payable/accounts_payable_service_documents';
import { useGetAccountsPayableServiceByBusinessId } from '@src/hooks/queries/accounts_payable/accounts_payable_services';
import { useFilter } from '@src/hooks/url_params';
import { TID } from '@src/types/common';

import { ItemsSelectorProvider } from '@src/components/utils/items_selector';
import InfoBanner from './info_banner';
import Filter, { ISettlementsFilter, ISettlementsQueryFilter } from './filter';
import SettlementsCounterInfo from './settlements_counter_info';
import Header from './settlements_header';
import Table from './settlements_table';

interface IAccountsPayableSettlementsProps {
  businessId: TID,
  serviceId: TID,
  type: string,
}

const getSettlementParamsFromFilter = (
  type: string,
  filterData: ISettlementsFilter | undefined,
): ISettlementsQueryFilter => {
  const filterParams: ISettlementsQueryFilter = omit(filterData || { queueType: type }, ['invoiceDate', 'paymentDate', 'amount']);
  filterParams.queueType = type;
  if (filterData?.invoiceDate) {
    filterParams.invoiceDateStart = filterData.invoiceDate.gte;
    filterParams.invoiceDateEnd = filterData.invoiceDate.lte;
  }
  if (filterData?.paymentDate) {
    filterParams.paymentDateStart = filterData.paymentDate.gte;
    filterParams.paymentDateEnd = filterData.paymentDate.lte;
  }
  if (filterData?.amount) {
    filterParams.invoiceAmountValue = filterData.amount.val;
    filterParams.invoiceAmountMin = filterData.amount.gte;
    filterParams.invoiceAmountMax = filterData.amount.lte;
  }
  return filterParams;
};

const AccountsPayableSettlements = ({
  businessId,
  serviceId,
  type,
}: IAccountsPayableSettlementsProps) => {
  const { data: accountsPayableServices } = useGetAccountsPayableServiceByBusinessId(businessId);
  const filter = useFilter<ISettlementsFilter>({});
  const intercompanyEnabled: boolean = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.INTERCOMPANY_AUTOMATION_FLAG);

  const filterParams = useMemo(() => {
    return getSettlementParamsFromFilter(type, filter.data);
  }, [type, filter.data]);

  const settlementsQuery = useGetAccountsPayableServiceDocuments({
    serviceId,
    filters: filterParams,
  });

  const settlements = useMemo(() => {
    const settlementsPages = settlementsQuery.data?.pages || [];
    return flatten(settlementsPages.map((p) => p.collection));
  }, [settlementsQuery.data]);

  return (
    <ItemsSelectorProvider allItems={ settlements }>
      <Header
        businessId={ businessId }
        type={ type }
      />
      {intercompanyEnabled && accountsPayableServices?.hasSettlementItems && <InfoBanner />}
      <SettlementsCounterInfo
        accountsPayableService={ accountsPayableServices }
        businessId={ businessId }
        type={ type }
      />
      <div className="page-main-body">
        <div className="banking-accounts-table-wrapper">
          <Filter
            businessId={ businessId }
            filter={ filter }
          />
          <Table
            accountsPayableServices={ accountsPayableServices }
            query={ settlementsQuery }
            settlements={ settlements }
          />
        </div>
      </div>
    </ItemsSelectorProvider>
  );
};

export default React.memo(AccountsPayableSettlements);
