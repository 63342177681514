import React from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IGetFinancialInstitutionConnectionResponseInfinite } from '@src/requests/financial_institution_connection';
import { TID } from '@src/types/common';
import { IFilter } from '@src/types/filter';
import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';

import { Button } from '@src/components/ui/buttons';
import DetailsPageTitle from '@src/components/ui/details_page_title';
import { PlusIcon } from '@src/components/utils/icomoon';

import Filter, { IFinancialInstitutionConnectionFilter } from '../filter';
import Table from './financial_institution_connection_table';

import styles from '../styles.module.scss';

interface IFinancialInstitutionConnectionCenterTableProps {
  query: UseInfiniteQueryResult<IGetFinancialInstitutionConnectionResponseInfinite, Error>,
  businessId?: TID,
  filter: IFilter<IFinancialInstitutionConnectionFilter>;
  financialInstitutionConnections: IFinancialInstitutionConnection[],
  onConnectFinancialInstitution: () => void,
  refetchFinancialConenctionList: () => void
}

const FinancialInstitutionConnectionCenterTable = ({
  query,
  businessId,
  filter,
  financialInstitutionConnections,
  onConnectFinancialInstitution,
  refetchFinancialConenctionList,
}: IFinancialInstitutionConnectionCenterTableProps): JSX.Element => {
  return (
    <div className={ styles['main-details'] }>
      <DetailsPageTitle
        isSmallTitle
        title="Manage your Financial Institution accounts that Docyt has access to."
      >
        <Filter
          businessId={ businessId }
          filter={ filter }
        />
        <Button
          bsColor="blue"
          onClick={ onConnectFinancialInstitution }
        >
          <PlusIcon mr={ 10 } />
          Connect
        </Button>
      </DetailsPageTitle>
      <div className="banking-accounts-table-wrapper">
        <Table
          financialInstitutionConnections={ financialInstitutionConnections }
          refetchFinancialConenctionList={ refetchFinancialConenctionList }
          query={ query }
        />
      </div>
    </div>
  );
};

export default FinancialInstitutionConnectionCenterTable;
